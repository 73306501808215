export const pColor = "#8159af";
export const sColor = "#dd843a";
export const sColorH = "#E16B08";
export const tColor = "#F39651";
export const pColorV2 = "#ebe7fb";


export const pColorDark = "#4113680";

// Colors
export const dangerColor = "#ff1744";
export const infoColor = "#53EAE6";

// Buttons
export const successButtonColor = "#158851";

// Buttons chat
export const ButtonColor = "#fff";


// Letters
export const pLetterColor = "#fff";