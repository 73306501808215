import React, { Component } from 'react';
import { Input, InputAdornment, Grid, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { showBackdrop } from 'services/actions/CustomAction';
import { useHistory } from 'react-router-dom';
import ItemErrorRow from '../Components/ItemErrorRow';
import { listErrors } from 'services/actions/ErrorAction';
import CustomModal from 'components/Modals/common/CustomModal';

const AdminErrorSection = props => {
  const { errorRx } = props;
  const history = useHistory();

  const [searchTimeout, setSearchTimeout] = React.useState(null);
  const [showReportedErrorModal, setShowReportedErrorModal] = React.useState(false);

  React.useEffect(() => {
    onList('');
  }, []);

  const onList = term => {
    props.dispatch(showBackdrop(true));
    props.dispatch(listErrors(term)).then(res => {
      props.dispatch(showBackdrop(false));
    })
    .catch(err => props.dispatch(showBackdrop(false)));
  };

  const onSearch = term => {
    clearTimeout(searchTimeout);
    setSearchTimeout(
      setTimeout(() => {
        onList(term);
      }, 1000)
    );
  };

  const openReportedErrorModal = () => {
    setShowReportedErrorModal(true);
  }

  const goTo = error => {
    history.push("/error-info/" + error.id);
  };

  const onSaveForm = () => {
    onList('');
  };

  return (
    <div style={{ height: '79vh' }}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12} style={{height: "5vh"}}>
          <div className="chatlist__heading">
            <span className="divider-line"></span>
            <p className="divider-content">Errores Reportados</p>
            <span className="divider-line"></span>
          </div>
          <br />
        </Grid>
        <Grid item xs={12} style={{ padding: '10px 10px', height: "7vh" }}>
          <Input
            fullWidth
            className="search_wrap"
            type="text"
            placeholder="Buscar error"
            onChange={event => onSearch(event.target.value)}
            disableUnderline
            startAdornment= {
              <InputAdornment position="start">
                <IconButton type="button" aria-label="search">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
        <Grid item xs={12} style={{height: "67vh"}}>
          <div className="chat-list-items">
            {errorRx.error && errorRx.error.map((error, i) => {
              return <ItemErrorRow key={i} error={error} goTo={goTo} />;
            })}
          </div>
        </Grid>
      </Grid>
      <CustomModal
          customModal={'ModalReportedErrors'}
          open={showReportedErrorModal}
          handleClose={() => setShowReportedErrorModal(false)}
          onSaveForm={onSaveForm}
        />
    </div>
  );
};

export default AdminErrorSection;
