import React from 'react';
import ReactDOM from 'react-dom';
import ZendyAppShell from 'ZendyAppShell';
import * as serviceWorker from './serviceWorker';

import store from './services/redux/common/Store'
import {Provider} from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
  
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <ZendyAppShell />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>,
  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
