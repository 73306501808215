//AUTH
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//SHOP COMMONS
export const GET_INVENTORIES = 'GET_INVENTORIES';
export const UPDATE_CURRENT_SHOP = 'UPDATE_CURRENT_SHOP';
export const UPDATE_LAST_ROUTE = 'UPDATE_LAST_ROUTE';
export const UPDATE_LAST_TAB = 'UPDATE_LAST_TAB';

//SNACKBARS
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

//BACKDROPS
export const SHOW_BACKDROP = 'SHOW_BACKDROP';
export const HIDE_BACKDROP = 'HIDE_BACKDROP';

//ENTRY QUERY
export const ENTRY_QUERY = 'ENTRY_QUERY';

// NOTIFICATIONS
export const NOTIFICATIONS = 'NOTIFICATIONS';

// NOTIFICATIONS
export const NOTIFICATIONS_VIEWED = 'NOTIFICATIONS_VIEWED';

//RECOMMENDATIONS
export const MY_RECOMMENDATIONS = 'MY_RECOMMENDATIONS';

//CURRENT CHAT
export const CURRENT_CHAT = 'CURRENT_CHAT';
//ERROR
export const ERROR = 'ERROR';
//N° CONSULTAS_ACEPTADAS
export const NUM_CONSULTAS = 'NUM_CONSULTAS_ACEPTADAS';
//N° CONSULTAS_PENDIENTES
export const NUM_CONSULTAS_PENDIENTES = 'NUM_CONSULTAS_PENDIENTES';
//N° MENSAJES_CHATS
export const NUM_MENSAJES_CHATS = 'NUM_MENSAJES_CHATS';
export const STATUS_PUSHER = 'STATUS_PUSHER';
export const ID_CHATS = 'ID_CHATS';
export const POS_MODAL = 'POS_MODAL';

